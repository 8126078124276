import * as React from 'react'
import PropTypes from 'prop-types'
import { PageProps } from 'gatsby'

import Layout from './layout'
import Seo from './seo'

type PageContextProps = {
    frontmatter: {
        title: string,
        path: string,
        date: string,
    }
}
const DefaultPageLayout: React.FC<PageProps<null, PageContextProps>> = ({ children, pageContext: { frontmatter } }) => {

    return (
        <Layout>
            <Seo title={ frontmatter.title } />
            <div className="my-6 p-8 bg-white">
                <article className="prose md:prose-sm max-w-none
                    prose-h1:text-[#077ab3] prose-h1:uppercase prose-h1:font-bold prose-h2:text-[#87c2e1]
                    prose-h3:text-base md:prose-h3:text-base prose-p:text-justify
                    prose-a:text-[#0088cc] prose-a:font-bold prose-a:underline-offset-2 hover:prose-a:text-[#005580]">
                    { children }
                </article>
            </div>
        </Layout>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default DefaultPageLayout
