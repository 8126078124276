import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/kszol/projects/noeadogatsby/src/components/layout/default-page-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 className="sr-only">Technikai szám</h1>
    <p>{`A NOÉ Állatotthon Alapítvány védencei javára nem tudod felajánlani személyi jövedelemadód másik 1%-t.`}</p>
    <p>{`Ha szeretnéd biztosan jó helyen tudni ezt a felajánlásodat, akkor ajánljuk barátainkat, a Magyarországi Krisna-tudatú Hívőket. Ételosztásaikkal, természettudatos, állatvédő és állatbarát szemléletükkel és munkájukkal nagyon sokat tesznek.`}</p>
    <p>{`Magyraországi Krisna-tudatú Hívők Közössége
technikai szám: 0389`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      